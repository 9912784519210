/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import bgFollowUs from '../../../../../site/content/assets/flexiblocks/homepage/bg-follow-us.svg'

export default {
  header: {
    bg: '#27A8FF',
    pt: '5rem',
  },
  featured: {
    pt: '3rem',
    pb: '3rem'
  },
  follow: {
    bg: '#F7F8FC',
    backgroundImage: `url(${bgFollowUs})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    backgroundSize: 'cover',
    '@media (max-width: 765px)': {
      backgroundSize: 'cover',
      pb: '3rem'
    }
  },
  contact: {
    bg: '#27A8FF'
  },
  allPosts: {
    pt: '5rem',
    pb: '5rem',
    bg: '#FFFFFF'
  },
  pictures: {
    pb: '3rem'
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    pt: '3rem',
    mb: '1rem',
    color: '#2A205E',
    '@media (max-width: 765px)': {
      fontSize: 30,
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitle: {
    mt: '-1rem',
    fontSize: '18px',
    pb: '1rem'
  },
  card: {
    border: '1px solid #42B3FF',
    color: '#42B3FF',
    height: '35px',
    paddingTop: '9px',
    borderRadius: '9px',
    padding: '3px 15px 3px 15px',
    width: 'max-content',
    marginBottom: '2rem',
    '@media (max-width: 991px)': {
      margin: 'auto'
    },
    '@media (max-width: 749px)': {
      margin: 'initial'
    },
    '@media (max-width: 743px)': {
      padding: '5px 15px 3px 15px',
    }
  }
}
