import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import theme from './_theme'
import styles from './_styles'

import favicon from '../../../../../site/content/assets/favicon.png'
import { Container, Box, Text, Card, css } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import Posts from '@solid-ui-blocks/Educabot/Novedades/Blog/Block01'
import CardList from '../../../../flow-ui/flow-ui-components/src/CardListEducabot/CardList'
import AllPosts from '@solid-ui-blocks/Educabot/Novedades/AllPosts/Block01'
import Follow from '@solid-ui-blocks/Educabot/Novedades/Follow/Block06'
import Contact from '@solid-ui-blocks/Educabot/Hero/Block06'
import {
  PostFooter
} from '../../../../flow-ui/flow-ui-widgets/src/Post/Post.Footer'
import { useRecentPosts } from '../../../../blog/gatsby-blog-helpers/src/useRecentPosts'

import WithRecentPosts from '@solid-ui-blocks/Educabot/Novedades/WithRecentPosts'

import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'


const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Box sx={styles.featured}>
        <Container sx={styles.containerPost}>
          <Box>
            <Card m='2' bg="#E5F1FC" sx={styles.card}>
              Novedades
            </Card>
          </Box>
        <WithRecentPosts>
          <CardList 
            nodes={useRecentPosts()}
            variant='vertical-cover'
            limit={6}
            omitCategory
            slider
            autoPlay
            arrows={true}
            columns={[1, 3, 3, 3]}
          />
        </WithRecentPosts>
        </Container>
      </Box>

      <Box sx={styles.allPosts}>
      <WithRecentPosts limit={100}>
        <AllPosts content={content['all-posts-educabot']} />      
      </WithRecentPosts>
      </Box>

      <Box sx={styles.follow}>
        <Follow content={content['follow-us']} reverse />
      </Box>

      {/* <Box sx={styles.contact}>
        <Contact content={content['formContact']} reverse />
      </Box> */}
      <Footer content={content['footer']} />

    </Layout>
  )
}

export const query = graphql`
  query novedadesBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
